import "./sop.css";
import Header from "../../components/Header";
import Title from "../../components/Title";
import {
  MdWeb,
  MdAddCircleOutline
} from "react-icons/md";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Sop() {
    const [profile, setProfile] = useState({});

    useEffect(() => {
        setProfile(JSON.parse(sessionStorage.getItem("LASOP")));
    
        return () => {};
      }, []);


  return (
    <div>
      <Header />

      <div className="content">
        <Title name=" Inclusão de SOPs">
          <MdWeb size={25} />
        </Title>

        {profile.isAdmin && (
        
            <Link to="/new" className="new">
              <MdAddCircleOutline size={30} color="#FFF" />
              Novo SOP
            </Link>
        )}
      </div>
    </div>
  );

  
}
