import { useState, useEffect } from "react";
import "./newCode.css";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { MdPersonAdd } from "react-icons/md";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import DeleteModalCode from "../../components/DeleteModalCode";

export default function NewCode() {
  const { id } = useParams();
  const history = useHistory();

  const [loadCodes, setLoadCodes] = useState(true);
  const [codes, setCodes] = useState([]);
  const [idUser, setIdUser] = useState(false);
  const [uSelected, setUSelected] = useState([]);
  const [codigo, setCodigo] = useState("");
  const [showPostModal, setShowPostModal] = useState(false);



  function togglePostModal(id) {
    setShowPostModal(!showPostModal);
  }

  useEffect(() => {
    async function loadCodes() {
      await firebase
        .firestore()
        .collection("codes")
        .orderBy("codigo", "asc")
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              codigo: doc.data().codigo,
            });
          });
          if (lista.length === 0) {
            console.log("Nenhum código encontrado");
            setCodes([{ id: "1", codigo: "" }]);
            setLoadCodes(false);
            return;
          }

          setCodes(lista);
          setLoadCodes(false);
          
      
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadCodes(false);
          setCodes([{ id: "1", codigo: "" }]);
        });
    }
    loadId();
    loadCodes();

  }, []);


  async function loadId(lista) {
    await firebase
      .firestore()
      .collection("codes")
      .doc(id)
      .get()
      .then((snapshot) => {
        setCodigo(snapshot.data().codigo);

        let index = lista.findIndex((item) => item.id === snapshot.data().id);

        setUSelected(index);
        setIdUser(true);
      })
      .catch((err) => {
        console.log("Erro no ID recebido: ", err);
        setIdUser(false);
      });
         
  }

  //Cadastra CÓDIGOS no banco
  async function handleRegisterCode(e) {
    e.preventDefault();
    if (id) {
      await firebase
        .firestore()
        .collection("codes")
        .doc(id)
        .update({
          codigo: codigo,
        })
        .then(() => {
          toast.success("Código editado com sucesso");
          history.push("/code");
        })
        .catch((err) => {
          console.log(err);
        });
    }else{
    if (codigo !== "") {
      await firebase
        .firestore()
        .collection("codes")
        .add({
          codigo: codigo,
        })
        .then(() => {
          toast.info("Código cadastrado com sucesso");
          history.push("/code");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Erro ao cadastrar código");
        });
    } else {
      toast.warning("Preencha todos os campos!");
    }
    }
    // loadCodes();
  }


  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Cadastros">
          <MdPersonAdd size={25} />
        </Title>

        <div className="container-center">
          <div className="container-codes">
            <form className="form-codes" onSubmit={handleRegisterCode}>
              <input
                type="text"
                placeholder="Cadastro de códigos"
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
              />
              <button type="submit">Salvar</button>
              {id &&
              <button type="button" onClick={()=>togglePostModal(id) } style={{backgroundColor: "#f87b7b", color: "#fff", marginTop: "-10px"}}>Excluir</button>
              }
            </form>
          </div>

      </div>
      {showPostModal && <DeleteModalCode close={togglePostModal} />}
    </div>
    </div>
  );
}
