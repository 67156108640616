import { useState, createContext, useEffect } from "react";
import firebase from "../services/firebaseConnection";
import { toast } from "react-toastify";

export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState();
  const [userGroup, setUserGroup] = useState([]);

  useEffect(() => {
    function loadStorage() {
      const storageUser = sessionStorage.getItem("LASOP");
      if (storageUser) {
        setUser(JSON.parse(storageUser));
        setLoading(false);
      }

      setLoading(false);
    }

    loadStorage();
  }, []);

  //Login do usuário
  async function signIn(email, password) {
    setLoadingAuth(true);

    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (value) => {
        let uid = value.user.uid;

        const userProfile = await firebase
          .firestore()
          .collection("new-users")
          .doc(uid)
          .get();
        let data = {
          uid: uid,
          nome: userProfile.data().nome,
          perfilUsuario: userProfile.data().perfilUsuario,
          grupos: userProfile.data().grupo,
          email: value.user.email,
          dataAlteracao: userProfile.data().dataAlteracao,
          arrayPerfilUsuario: userProfile.data().arrayPerfilUsuario,
          isAdmin: userProfile.data().isAdmin
        };
        const perfil = userProfile.data().perfilUsuario;
        const grupoUsuario = userProfile.data().grupo;

        setUserGroup(grupoUsuario);
        setUserProfile(perfil);
        setUser(data);
        storageUser(data);
        setLoadingAuth(false);
        toast.success("Bem vindo ao LASOP!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Email ou senha incorretos");
        setLoadingAuth(false);
      });
  }

  //Cadastrando novo usuário
  async function signUp(
    email,
    password,
    nome,
    perfilUsuario,
    typeUserSelected,
    groupSelected,
    changeDate
  ) {
    setLoadingAuth(true);
    await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (value) => {
        var uid = value.user.uid;
        await firebase
          .firestore()
          .collection("new-users")
          .doc(uid)
          .set({
            nome: nome,
            email: email,
            password: password,
            perfilUsuario: perfilUsuario,
            tipoUsuario: typeUserSelected,
            grupo: groupSelected,
            dataAlteracao: changeDate,
          })
          .then(() => {
            let data = {
              uid: uid,
              nome: value.nome,
              perfilUsuario: value.perfilUsuario,
              email: value.user.email,
            };

            setUser(data);
            setLoadingAuth(false);
            toast.success("Usuário cadastrado com sucesso!");
          });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ops! Algo deu errado");
        setLoadingAuth(false);
      });
  }

  function storageUser(data) {
    sessionStorage.setItem("LASOP", JSON.stringify(data));
  }

  //Logout do usuário
  async function signOut() {
    await firebase.auth().signOut();
    sessionStorage.removeItem("LASOP");
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signUp,
        signOut,
        signIn,
        loadingAuth,
        userProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
