import "./records.css";
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { MdAddCircleOutline, MdEdit, MdPersonOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import firebase from "../../services/firebaseConnection";

const listRefUser = firebase
  .firestore()
  .collection("new-users")
  .orderBy("nome", "asc");

export default function Records() {
  const [getUsers, setGetUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPostModal, setShowPostModal] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  function togglePostModal() {
    setShowPostModal(!showPostModal);
  }

  useEffect(() => {
    async function loadUsers() {
      await listRefUser
        .get()
        .then((snapshot) => {
          updateState(snapshot);
        })
        .catch((err) => {
          console.log(err);
        });

      setLoading(false);
    }
    loadUsers();

    return () => {};
  }, []);

  
  function order(a, b) {
    return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
  }

  async function updateState(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];
      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          nome: doc.data().nome,
          email: doc.data().email,
          perfilUsuario: doc.data().arrayPerfilUsuario,
        });
      });
      setGetUsers((getUsers) => [...getUsers, ...lista]);
    } else {
      setIsEmpty(true);
    }
  }

  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Cadastros">
            <MdPersonOutline size={25} />
          </Title>

          <div className="container records">
            <span>Buscando usuários...</span>
          </div>
        </div>
      </div>
    );
  }

  

  return (
    <div>
      <Header />

      <div className="content">
        <Title name="Cadastros">
          <MdPersonOutline size={25} />
        </Title>

        {getUsers.length === 0 ? (
          <div className="container records">
            <span>Nenhum usuário cadastrado...</span>
            <Link to="/newrecords" className="new">
              <MdAddCircleOutline size={25} color="#FFF" />
              Novo usuário
            </Link>
          </div>
        ) : (
          <>
            <Link to="/newrecords" className="new">
              <MdAddCircleOutline size={25} color="#FFF" />
              Novo usuário
            </Link>

            <table>
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">E-Mail</th>
                  <th scope="col">Perfil do usuário</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {getUsers.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Nome">{item.nome}</td>
                      <td data-label="E-Mail">{item.email}</td>
                      <td data-label="PerfilUsuario">{item.perfilUsuario.join(", ")}</td>
                      <td data-label="#">
                        {/* <button className='action' style={{backgroundColor:"#3583f6"}} onClick={()=>togglePostModal() }>
                                <MdVisibility color="#fff" size={17}/>
                            </button> */}
                        <Link
                          className="action"
                          style={{ backgroundColor: "#f6a935" }}
                          to={`/newrecords/${item.id}`}
                        >
                          <MdEdit color="#fff" size={17} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
      {showPostModal && <Modal close={togglePostModal} />}
    </div>
  );
}
