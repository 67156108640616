import { Switch } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import New from "../pages/New";
import NewRecords from "../pages/NewRecords";
import Records from "../pages/Records";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Standards from "../pages/Standards";
import WordSearch from "../pages/WordSearch";
import Sop from "../pages/Sop";
import Codes  from "../pages/Codes";
import NewCode  from "../pages/NewCode";
import Group  from "../pages/Group";
import NewGroup  from "../pages/NewGroup";
import TypeUser  from "../pages/TypeUser";
import NewTypeUser  from "../pages/NewTypeUser";

import Route from "./Route";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/dashboard" component={Dashboard} isPrivate />
      <Route exact path="/sop" component={Sop} isPrivate />
      <Route exact path="/code" component={Codes} isPrivate />
      <Route exact path="/newcode" component={NewCode} isPrivate />
      <Route exact path="/newcode/:id" component={NewCode} isPrivate />
      <Route exact path="/group" component={Group} isPrivate />
      <Route exact path="/newgroup" component={NewGroup} isPrivate />
      <Route exact path="/newgroup/:id" component={NewGroup} isPrivate />
      <Route exact path="/typeuser" component={TypeUser} isPrivate />
      <Route exact path="/newtypeuser" component={NewTypeUser} isPrivate />
      <Route exact path="/newtypeuser/:id" component={NewTypeUser} isPrivate />
      <Route exact path="/changePassword" component={SignUp} isPrivate />
      <Route exact path="/newrecords" component={NewRecords} isPrivate />
      <Route exact path="/newrecords/:id" component={NewRecords} isPrivate />
      <Route exact path="/records" component={Records} isPrivate />
      <Route exact path="/standards/:dep" component={Standards} isPrivate />
      <Route exact path="/wordsearch" component={WordSearch} isPrivate />
      <Route exact path="/new" component={New} isPrivate />
      <Route exact path="/new/:id" component={New} isPrivate />
    </Switch>
  );
}
