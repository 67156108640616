import Header from "../../components/Header";
import Title from "../../components/Title";
import { MdLanguage } from "react-icons/md";
import "./wordSearch.css";

export default function WordSearch() {
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Word Search">
          <MdLanguage size={25} />
        </Title>
        <div className="container-WS">
          <p>
          Aplicação de gerenciamento de terminologia da Language Associates.
          Estamos migrando dados do antigo SGGloss e esta funcionalidade estará disponível em 01/09/2023.
          </p>
          <br/>
          <p>
          Language Associates proprietary terminology management application. 
          This feature will be available by 01-Sep-2023.
          </p>
        </div>
      </div>
    </div>
  );
}
