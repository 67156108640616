import { MdClose } from "react-icons/md";
import "./deleteModalTypeUser.css";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";

export default function DeleteModalCode({ conteudo, close }) {
  const history = useHistory();
  const { id } = useParams();


  function handleDelete(e){
    e.preventDefault();
    if(id){
      firebase
      .firestore()
      .collection("type-users")
      .doc(id)
      .delete()
      .then(() => {
        toast.success("Tipo de usuário excluido com sucesso.")
        history.push("/typeuser");
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }

  return (
    <div className="deleteType">
      <div className="container">
        <button className="fechaType" onClick={close}>
          <MdClose size={23} color="#fff" />
        </button>

        <div>
       
          <h2>Exclusão de tipo de usuário</h2>
        
          <div className="row">
            <span >
              Tem certeza que deseja excluir este tipo de usuário?
            </span>
          </div>
          <div className="button">
            <button className="cancel" onClick={close}>
              Cancelar
            </button>
            <button className="delete" onClick={handleDelete}>
              Excluir
            </button>
          </div>
       

        </div>
      </div>
    </div>
  );
}