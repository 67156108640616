import { useState, useEffect } from "react";
import "./newTypeUser.css";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { MdFace } from "react-icons/md";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import DeleteModalTypeUser from "../../components/DeleteModalTypeUser";

export default function NewGroup() {
  const { id } = useParams();
  const history = useHistory();

  const [loadTypeUsers, setLoadTypeUsers] = useState(true);
  const [typeUsers, setTypeUsers] = useState([]);
  const [idUser, setIdUser] = useState(false);
  const [uSelected, setUSelected] = useState([]);
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [showPostModal, setShowPostModal] = useState(false);



  function togglePostModal(id) {
    setShowPostModal(!showPostModal);
  }

  useEffect(() => {
    async function loadTypeUsers() {
      await firebase
        .firestore()
        .collection("type-users")
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              tipoUsuario: doc.data().tipoUsuario,
            });
          });
          if (lista.length === 0) {
            console.log("Nenhum grupo encontrado");
            setLoadTypeUsers(false);
            return;
          }

          setLoadTypeUsers(false);

          if (id) {
            loadId(lista);
          }
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadTypeUsers(false);
        });
    }

    loadTypeUsers();
  }, []);


  async function loadId(lista) {
    await firebase
      .firestore()
      .collection("type-users")
      .doc(id)
      .get()
      .then((snapshot) => {
        setTipoUsuario(snapshot.data().tipoUsuario);

        let index = lista.findIndex((item) => item.id === snapshot.data().id);

        setUSelected(index);
        setIdUser(true);
      })
      .catch((err) => {
        console.log("Erro no ID recebido: ", err);
        setIdUser(false);
      });
         
  }

  //Cadastra GRUPOS no banco
  async function handleRegisterTypeUser(e) {
    e.preventDefault();
    if (id) {
      await firebase
        .firestore()
        .collection("type-users")
        .doc(id)
        .update({
          tipoUsuario: tipoUsuario,
        })
        .then(() => {
          toast.success("Tipo de usuário editado com sucesso");
          history.push("/typeuser");
        })
        .catch((err) => {
          console.log(err);
        });
    }else{
    if (tipoUsuario !== "") {
      await firebase
        .firestore()
        .collection("type-users")
        .add({
          tipoUsuario: tipoUsuario,
        })
        .then(() => {
            toast.info("Tipo de usuário cadastrado com sucesso");
            history.push("/typeuser");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Erro ao cadastrar código");
        });
    } else {
      toast.warning("Preencha todos os campos!");
    }
    }
    // loadGroups();
  }


  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Tipos de usuário">
          <MdFace size={25} />
        </Title>

        <div className="container-center">
          <div className="container-type-user">
            <form className="form-codes" onSubmit={handleRegisterTypeUser}>
            <input
                type="text"
                placeholder="Cadastro de tipos de usuários"
                value={tipoUsuario}
                onChange={(e) => setTipoUsuario(e.target.value)}
              />
              <button type="submit">Salvar</button>
              {id &&
              <button type="button" onClick={()=>togglePostModal(id) } style={{backgroundColor: "#f87b7b", color: "#fff", marginTop: "-10px"}}>Excluir</button>
              }
            </form>
          </div>
      </div>
      {showPostModal && <DeleteModalTypeUser close={togglePostModal} />}
    </div>
    </div>
  );
}
