import "./typeUser.css";
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { MdAddCircleOutline, MdEdit, MdFace } from "react-icons/md";
import { Link } from "react-router-dom";
import firebase from "../../services/firebaseConnection";

const listRefTypeUser = firebase
  .firestore()
  .collection("type-users")
  .orderBy("tipoUsuario", "asc")

export default function TypeUser() {
  const [getTypeUser, setGetTypeUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    async function loadTypeUser() {
      await listRefTypeUser
        .get()
        .then((snapshot) => {
          updateState(snapshot);
        })
        .catch((err) => {
          console.log(err);
        });

      setLoading(false);
    }
    loadTypeUser();

    return () => {};
  }, []);

  async function updateState(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];
      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          nome: doc.data().tipoUsuario,
        });
      });
      setGetTypeUser((getTypeUsers) => [...getTypeUsers, ...lista]);
    } else {
      setIsEmpty(true);
    }
  }

  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Tipos de usuário">
            <MdFace size={25} />
          </Title>

          <div className="container records">
            <span>Buscando tipos de usuários...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />

      <div className="content">
      <Title name="Tipos de usuário">
            <MdFace size={25} />
          </Title>

        {getTypeUser.length === 0 ? (
          <div className="container records">
            <span>Nenhum tipo de usuário cadastrado...</span>
            <Link to="/newtypeuser" className="new">
              <MdAddCircleOutline size={25} color="#FFF" />
              Novo tipo de usuário
            </Link>
          </div>
        ) : (
          <>
            <Link to="/newtypeuser" className="new">
              <MdAddCircleOutline size={25} color="#FFF" />
              Novo tipo de usuário
            </Link>

            <table>
              <thead>
                <tr>
                  <th scope="col">Tipo de usuário</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {getTypeUser.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="TipoUsuario">{item.nome}</td>
                      <td data-label="#">
                        <Link
                          className="action"
                          style={{ backgroundColor: "#f6a935" }}
                          to={`/newtypeuser/${item.id}`}
                        >
                          <MdEdit color="#fff" size={17} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}
