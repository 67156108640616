import { useContext, useState } from "react";
import firebase from "../../services/firebaseConnection";
import logo from "../../assets/Logo.png";
import { AuthContext } from "../../contexts/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import './signUp.css';

function SignUp() {
  const [senhaAtual, setSenhaAtual] = useState("");
  const [novaSenha, setNovaSenha] = useState("");
  const [repitaSenha, setRepitaSenha] = useState("");
  const history = useHistory();

  const { loadingAuth, user } = useContext(AuthContext);


  async function onChangePassword(e) {
    e.preventDefault();

    const user = firebase.auth().currentUser;
    const credentials = firebase.auth.EmailAuthProvider.credential(
      user.email,
      senhaAtual
    );
    user.reauthenticateWithCredential(credentials).then(() => {
    if (senhaValida(novaSenha)) {
        user
        .updatePassword(novaSenha)
        .then(function () {
          firebase
            .firestore()
            .collection("new-users")
            .doc(user.uid)
            .update({
              dataAlteracao: moment(new Date()).format("DD/MM/YYYY"),
              password: novaSenha,
            });
          toast.success(
          <div>
          Senha alterada com sucesso!
          <br/>
          <br/>
          Your password has been changed.
          </div>
          );
          return history.push("/dashboard");
        })
        .catch(function (error) {
          toast.warning("Erro: " + error);
        });
    } else {
      toast.warning(
        <div>
        O campo senha deve conter pelo menos 6 digitos, uma letra maiuscula, um número e um caracter especial.
        <br/>
        <br/>
        Your password must contain no less than 6 characters (a minimum of 1 upper case letter and 1 numeric character [0-9])
        </div>
      );
    }
  }).catch((error) => {
    console.log(error)
    toast.error("Falha. Tente novamente mais tarde!")
  })
  }

  return (
    <div className="container-center">
      <div className="login">
        <div className="login-area">
          <img src={logo} alt="Logotipo da Language Associates" />
        </div>
        <form onSubmit={onChangePassword}>
          <h1>Altere sua senha</h1>
          <input
            type="password"
            placeholder="Senha atual"
            value={senhaAtual}
            onChange={(e) => setSenhaAtual(e.target.value)}
          />
          <input
            type="password"
            placeholder="Nova senha"
            value={novaSenha}
            onChange={(e) => setNovaSenha(e.target.value)}
          />
          {novaSenha === repitaSenha ?
          <input
            type="password"
            placeholder="Repita sua senha"
            value={repitaSenha}
            onChange={(e) => setRepitaSenha(e.target.value)}
          />
          : 
          <input
          style={{border: "2px solid red"}}
          type="password"
          placeholder="Repita sua senha"
          value={repitaSenha}
          onChange={(e) => setRepitaSenha(e.target.value)}
        />
          }
          <button type="submit" className={novaSenha !== repitaSenha ? "hiddenMenuItem" : "undefined"} >
            {loadingAuth ? "Carregando..." : "Enviar"}
          </button>
             
        </form>
        {/* <Link to="/">Já tem cadastro? Entre</Link> */}
      </div>
    </div>
  );
}

function senhaValida(p) {
  var retorno = false;
  var letrasMaiusculas = /[A-Z]/;
  var letrasMinusculas = /[a-z]/;
  var numeros = /[0-9]/;
  var caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
  if (p.length > 8) {
    return !retorno;
  }
  if (p.length < 8) {
    return retorno;
  }
  var auxMaiuscula = 0;
  var auxMinuscula = 0;
  var auxNumero = 0;
  var auxEspecial = 0;
  for (var i = 0; i < p.length; i++) {
    if (letrasMaiusculas.test(p[i])) auxMaiuscula++;
    else if (letrasMinusculas.test(p[i])) auxMinuscula++;
    else if (numeros.test(p[i])) auxNumero++;
    else if (caracteresEspeciais.test(p[i])) auxEspecial++;
  }
  if (auxMaiuscula > 0) {
    if (auxMinuscula > 0) {
      if (auxNumero > 0) {
        if (auxEspecial) {
          retorno = true;
        }
      }
    }
  }
  return retorno;
}

export default SignUp;
