import "./group.css";
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { MdAddCircleOutline, MdEdit, MdGroupAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import firebase from "../../services/firebaseConnection";

const listRefGroup = firebase
  .firestore()
  .collection("groups")
  .orderBy("grupo", "asc")

export default function Group() {
  const [getGroup, setGetGroup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    async function loadGroup() {
      await listRefGroup
        .get()
        .then((snapshot) => {
          updateState(snapshot);
        })
        .catch((err) => {
          console.log(err);
        });

      setLoading(false);
    }
    loadGroup();

    return () => {};
  }, []);

  async function updateState(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];
      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          nome: doc.data().grupo,
        });
      });
      setGetGroup((getGroups) => [...getGroups, ...lista]);
    } else {
      setIsEmpty(true);
    }
  }

  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Grupos">
            <MdGroupAdd size={25} />
          </Title>

          <div className="container records">
            <span>Buscando usuários...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />

      <div className="content">
      <Title name="Grupos">
            <MdGroupAdd size={25} />
          </Title>

        {getGroup.length === 0 ? (
          <div className="container records">
            <span>Nenhum usuário cadastrado...</span>
            <Link to="/newgroup" className="new">
              <MdAddCircleOutline size={25} color="#FFF" />
              Novo grupo
            </Link>
          </div>
        ) : (
          <>
            <Link to="/newgroup" className="new">
              <MdAddCircleOutline size={25} color="#FFF" />
              Novo grupo
            </Link>

            <table>
              <thead>
                <tr>
                  <th scope="col">Grupo</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {getGroup.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Grupo">{item.nome}</td>
                      <td data-label="#">
                        {/* <button className='action' style={{backgroundColor:"#3583f6"}} onClick={()=>togglePostModal() }>
                                <MdVisibility color="#fff" size={17}/>
                            </button> */}
                        <Link
                          className="action"
                          style={{ backgroundColor: "#f6a935" }}
                          to={`/newgroup/${item.id}`}
                        >
                          <MdEdit color="#fff" size={17} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}
