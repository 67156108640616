import { useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import DeleteModalSop from "../../components/DeleteModalSop";
import Header from "../../components/Header";
import Title from "../../components/Title";
import firebase from "../../services/firebaseConnection";
import "./new.css";

export default function New() {
  const { id } = useParams();
  const history = useHistory();

  const [loadCodes, setLoadCodes] = useState(true);
  const [codes, setCodes] = useState([]);
  const [codeSelected, setCodeSelected] = useState(0);
  const [sopSelected, setSOPSelected] = useState([]);
  const [loadGroups, setLoadGroups] = useState(true);
  const [groups, setGroups] = useState([]);
  const [groupsSelected, setGroupsSelected] = useState(0);
  const [elaboradorPorSelected, setElaboradorPorSelected] = useState(0);
  const [revisadoPorSelected, setRevisadoPorSelected] = useState(0);
  const [aprovadoPorSelected, setAprovadoPorSelected] = useState(0);

  const [codigo, setCodigo] = useState([]);
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [elaboracao, setElaboracao] = useState("");
  const [revisao, setRevisao] = useState("");
  const [aprovacao, setAprovacao] = useState("");
  const [vigenciaInicial, setVigenciaInicial] = useState("");
  const [vigenciaFinal, setVigenciaFinal] = useState("");
  const [dataElaboracao, setDataElaboracao] = useState("");
  const [dataRevisao, setDataRevisao] = useState("");
  const [dataAprovacao, setDataAprovacao] = useState("");
  const [versao, setVersao] = useState("");
  const [idGroup, setIdGroup] = useState(false);
  const [document, setDocument] = useState(null);
  const [elaboradoPor, setElaboradoPor] = useState([]);
  const [revisadoPor, setRevisadoPor] = useState([]);
  const [aprovadoPor, setAprovadoPor] = useState([]);
  const [loadElaboradorPor, setLoadElaboradorPor] = useState(true);
  const [loadRevisadoPor, setLoadRevisadoPor] = useState(true);
  const [loadAprovadoPor, setLoadAprovadoPor] = useState(true);
  const [showPostModal, setShowPostModal] = useState(false);

  async function loadId(lista) {
    await firebase
      .firestore()
      .collection("new-standards")
      .doc(id)
      .get()
      .then((snapshot) => {
        setCodeSelected(snapshot.data().codigo);
        setTitulo(snapshot.data().titulo);
        setDescricao(snapshot.data().descricao);
        setGroupsSelected(snapshot.data().grupo);
        setElaboradorPorSelected(snapshot.data().elaboracao);
        setDataElaboracao(snapshot.data().dataElaboracao);
        setRevisadoPorSelected(snapshot.data().revisao);
        setDataRevisao(snapshot.data().dataRevisao);
        setAprovadoPorSelected(snapshot.data().aprovacao);
        setDataAprovacao(snapshot.data().dataAprovacao);
        setVigenciaInicial(snapshot.data().vigenciaInicial);
        setVigenciaFinal(snapshot.data().vigenciaFinal);
        setVersao(snapshot.data().versao);

        let index = lista.findIndex(
          (item) => item.id === snapshot.data().groupsId
        );
        setSOPSelected(index);
        setIdGroup(true);
      })
      .catch((err) => {
        console.log("Erro no id recebido: ", err);
        setIdGroup(false);
      });
  }

  function handleFile(e) {
    if (e.target.files[0]) {
      const documento = e.target.files[0];

      if (documento !== null) {
        setDocument(documento);
      } else {
        alert("Selecione um documento.");
        setDocument(null);
        return null;
      }
    }
  }

  async function handleUpload() {
    const currentId = idGroup.codigo;
    const uploadTask = await firebase
      .storage()
      .ref(`Documentos/${idGroup.codigo}/${document.name}`)
      .put(document)
      .then(async () => {
        console.log("Documento enviado com sucesso");
        await firebase
          .storage()
          .ref(`Documentos/${idGroup.uid}`)
          .child(document.name)
          .getDownloadURL()
          .then(async (url) => {
            let urlDoc = url;

            await firebase
              .firestore()
              .collection("new-standards")
              .doc(id)
              .update({
                documento: urlDoc,
              });
            toast.success("Documento enviado com sucesso");
          });
      });
  }

  async function handleRegister(e) {
    e.preventDefault();

    const grupoSelecionado = groupsSelected.map((item) => {
      return item.value;
    });

    //edita norma cadastrada
    if (idGroup && document === null) {
      await firebase
        .firestore()
        .collection("new-standards")
        .doc(id)
        .update({
          codigo: codeSelected,
          titulo: titulo,
          descricao: descricao,
          elaboracao: elaboradorPorSelected,
          dataElaboracao: dataElaboracao,
          grupo: groupsSelected,
          revisao: revisadoPorSelected,
          dataRevisao: dataRevisao,
          aprovacao: aprovadoPorSelected,
          dataAprovacao: dataAprovacao,
          vigenciaInicial: vigenciaInicial,
          vigenciaFinal: vigenciaFinal,
          versao: versao,
          arrayGrupo: grupoSelecionado,
        })
        .then(() => {
          toast.success("SOP editado com sucesso");
          history.push("/sop")
        })
        .catch((err) => {
          toast.error("Ops erro ao editar, tente mais tarde");
          console.log(err);
        });
      return;
    } else if (idGroup && document !== null) {
      handleUpload();
      return;
    }

    //Adiciona nova norma
    if (
      document === null &&
      titulo !== "" &&
      descricao !== "" &&
      elaboradorPorSelected !== "" &&
      revisadoPorSelected !== "" &&
      aprovadoPorSelected !== ""
    ) {
      await firebase
        .firestore()
        .collection("new-standards")
        .add({
          codigo: codeSelected,
          titulo: titulo,
          descricao: descricao,
          grupo: groupsSelected,
          elaboracao: elaboradorPorSelected,
          dataElaboracao: dataElaboracao,
          revisao: revisadoPorSelected,
          dataRevisao: dataRevisao,
          aprovacao: aprovadoPorSelected,
          dataAprovacao: dataAprovacao,
          vigenciaInicial: vigenciaInicial,
          vigenciaFinal: vigenciaFinal,
          versao: versao,
          documento: null,
          arrayGrupo: grupoSelecionado,
        })
        .then(() => {
          toast.info("Norma cadastrada com sucesso");
          history.push("/sop")
        })
        .catch((error) => {
          console.log(error);
          toast.error("Erro ao cadastrar norma");
        });
    } else {
      toast.warning("Preencha todos os campos!");
    }
  }

  // chamada api resgata códigos
  useEffect(() => {
    async function loadCodes() {
      await firebase
        .firestore()
        .collection("codes")
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              codigo: doc.data().codigo,
            });
          });
          if (lista.length === 0) {
            console.log("Nenhum código encontrado");
            setCodes([{ id: "1", codigo: "" }]);
            setLoadCodes(false);
            return;
          }

          setCodes(lista);
          setLoadCodes(false);
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadCodes(false);
          setCodes([{ id: "1", codigo: "" }]);
        });
    }

    loadCodes();
  }, []);


  // chamada api resgata grupos
  useEffect(() => {
    async function loadGroups() {
      await firebase
        .firestore()
        .collection("groups")
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              grupo: doc.data().grupo,
            });
          });
          if (lista.length === 0) {
            console.log("Nenhum grupo encontrado");
            setGroups([{ id: "1", codigo: "TESTE" }]);
            setLoadGroups(false);
            return;
          }

          setGroups(lista);
          setLoadGroups(false);

          if (id) {
            loadId(lista);
          }
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadGroups(false);
          setGroups([{ id: "1", grupo: "" }]);
        });
    }

    loadGroups();
  }, [id]);

 // chamada api resgata nomes usuários com query perfilUsuário Elaborado Por
  useEffect(() => {
    async function filterSelectOptionsElaboradoPor() {
      await firebase
        .firestore()
        .collection("new-users")
        .where("arrayPerfilUsuario", "array-contains-any", ["Administrador", "Redator"])
        .get()
        .then((snapshot) => {
          let listaProfile = [];
          snapshot.forEach((perfil) => {
            listaProfile.push({
              nome: perfil.data().nome,
            });
          });
          if (listaProfile.length === 0) {
            console.log("Nenhum grupo encontrado");
            setGroups([{ id: "1", codigo: "TESTE" }]);
            setLoadElaboradorPor(false);
            return;
          }
          setElaboradoPor(listaProfile);
          setLoadElaboradorPor(false);
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadElaboradorPor(false);
          setGroups([{ id: "1", grupo: "" }]);
        });
    }

    filterSelectOptionsElaboradoPor();
  }, []);

 // chamada api resgata nomes usuários com query perfilUsuário Revisado Por
  useEffect(() => {
    async function filterSelectOptionsRevisadoPor() {
      await firebase
        .firestore()
        .collection("new-users")
        .where("arrayPerfilUsuario", "array-contains-any", ["Administrador", "Revisor"])
        .get()
        .then((snapshot) => {
          let listaRevisor = [];
          snapshot.forEach((perfil) => {
            listaRevisor.push({
              nome: perfil.data().nome,
            });
          });
          if (listaRevisor.length === 0) {
            console.log("Nenhum grupo encontrado");
            setLoadRevisadoPor(false);
            return;
          }
          setRevisadoPor(listaRevisor);
          setLoadRevisadoPor(false);
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadRevisadoPor(false);
        });
    }

    filterSelectOptionsRevisadoPor();
  }, []);

  // chamada api resgata nomes usuários com query perfilUsuário Aprovado Por
   useEffect(() => {
    async function filterSelectOptionsAprovadoPor() {
      await firebase
        .firestore()
        .collection("new-users")
        .where("arrayPerfilUsuario", "array-contains-any", ["Administrador", "Aprovador"])
        .get()
        .then((snapshot) => {
          let listaAprovador = [];
          snapshot.forEach((perfil) => {
            listaAprovador.push({
              nome: perfil.data().nome,
            });
          });
          if (listaAprovador.length === 0) {
            console.log("Nenhum grupo encontrado");
            setLoadAprovadoPor(false);
            return;
          }
          setAprovadoPor(listaAprovador);
          setLoadAprovadoPor(false);
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadAprovadoPor(false);
        });
    }

    filterSelectOptionsAprovadoPor();
  }, []);


  //ordenação em ordem crescente
  function order(a, b) {
    return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
  }

 // popula combo grupos
  const options = groups.map((item, index) => {
    return {
      label: item.grupo,
      value: item.grupo,
    };
  }).sort(order);

  // popula combo ElaboradoPor
  const optionsElaboradoPor = elaboradoPor.map((item) => {
    return{
      label: item.nome,
      value: item.nome
    }
  }).sort(order);

   // popula combo RevisadoPor
  const optionsRevisadoPor = revisadoPor.map((item) => {
    return{
      label: item.nome,
      value: item.nome
    }
  }).sort(order);

   // popula combo AprovadoPor
  const optionsAprovadoPor = aprovadoPor.map((item) => {
    return{
      label: item.nome,
      value: item.nome
    }
  }).sort(order);

  const optionCodes = codes.map((item) => {
      return {
        label: item.codigo,
        value: item.codigo
      }
  }).sort(order);


  function handleChangeCodes(e) {
    console.log("INDEX DO CÓDIGO SELECIONADO", e.target.value);
    console.log("CÓDIGO SELECIONADO", codes[e.target.value].codigo);
    setCodeSelected(e.target.value);
  }

  function handleDelete(e){
    e.preventDefault();
    if(id){
      firebase
      .firestore()
      .collection("new-standards")
      .doc(id)
      .delete()
      .then(() => {
        toast.success("Norma excluida com sucesso.")
        history.push("/dashboard");
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 45
    }),

  };

  function togglePostModal(id) {
    setShowPostModal(!showPostModal);
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Novo SOP">
          <MdAddCircleOutline size={25} />
        </Title>

        <div className="container-new">
          <form className="form-new-user" onSubmit={handleRegister}>
            <label>Título</label>
            <input
              type="text"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />
            <label>Descrição</label>
            <textarea
              type="text"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
            <label>Grupos</label>
            {loadGroups ? (
              <input type="text" disabled={true} value="Carregando..." />
            ) : (
              <Select
                closeMenuOnSelect={false}
                isMulti
                value={groupsSelected}
                onChange={setGroupsSelected}
                options={options}
              />
            )}
            <div className="container-side">
              <div className="side-by-side">
                <label>Elaborado por</label>
                {loadElaboradorPor ? (
                <input type="text" disabled={true} value="Carregando..." />
            ) : (
              <Select
                closeMenuOnSelect={true}
                value={elaboradorPorSelected}
                onChange={setElaboradorPorSelected}
                options={optionsElaboradoPor}
                styles={customStyles}
              />
            )}
            {/* <input
              type="text"
              value={elaboracao}
              onChange={(e) => setElaboracao(e.target.value)}
            /> */}
            </div>
             <div className="side-by-side">
            <label>Data da elaboração</label>
            <input
              type="date"
              value={dataElaboracao}
              onChange={(e) => setDataElaboracao(e.target.value)}
            />
             </div>
            </div>
            <div className="container-side">
              <div className="side-by-side">
            <label>Revisado por</label>
            {loadRevisadoPor ? (
              <input type="text" disabled={true} value="Carregando..." />
            ) : (
              <Select
                closeMenuOnSelect={true}
                value={revisadoPorSelected}
                onChange={setRevisadoPorSelected}
                options={optionsRevisadoPor}
                styles={customStyles}
              />
            )}
            {/* <input
              type="text"
              value={revisao}
              onChange={(e) => setRevisao(e.target.value)}
            /> */}
              </div>
              <div className="side-by-side">
            <label>Data da revisão</label>
            <input
              type="date"
              value={dataRevisao}
              onChange={(e) => setDataRevisao(e.target.value)}
            />
             </div>
            </div>
            <div className="container-side">
              <div className="side-by-side">
            <label>Aprovado por</label>
            {loadAprovadoPor ? (
              <input type="text" disabled={true} value="Carregando..." />
            ) : (
              <Select
                closeMenuOnSelect={true}
                value={aprovadoPorSelected}
                onChange={setAprovadoPorSelected}
                options={optionsAprovadoPor}
                styles={customStyles}
              />
            )}
            {/* <input
              type="text"
              value={aprovacao}
              onChange={(e) => setAprovacao(e.target.value)}
            /> */}
            </div>
              <div className="side-by-side">
            <label>Data da aprovação</label>
            <input
              type="date"
              value={dataAprovacao}
              onChange={(e) => setDataAprovacao(e.target.value)}
            />
             </div>
            </div>
            <div className="container-side">
            <div className="side-by-side">
            <label>Código</label>
            {loadCodes ? (
              <input type="text" disabled={true} value="Carregando..." />
            ) : (
              <Select
              closeMenuOnSelect={true}
              value={codeSelected}
              onChange={setCodeSelected}
              options={optionCodes}
              />
              // <select value={codeSelected} onChange={handleChangeCodes} className="select">
              //   {codes.map((item, index) => {
              //     return (
              //       <option key={item.id} value={index}>
              //         {item.codigo}
              //       </option>
              //     );
              //   })}
              // </select>
            )}
            </div>
            <div className="side-by-side">
               <label>Versão</label>
            <input
              type="text"
              value={versao}
              onChange={(e) => setVersao(e.target.value)}
            />
            </div>
            </div>
            <div className="container-side">
              <div className="side-by-side">
            <label>Vigência inicial</label>
            <input
              type="date"
              value={vigenciaInicial}
              onChange={(e) => setVigenciaInicial(e.target.value)}
            />
             </div>
            <div className="side-by-side">
            <label>Vigência final</label>
            <input
              type="date"
              value={vigenciaFinal}
              onChange={(e) => setVigenciaFinal(e.target.value)}
            />
             </div>
            </div> 
            <div className="enviarArquivo">
              <label htmlFor="arquivo">Enviar arquivo</label>
              <input
                type="file"
                name="arquivo"
                id="arquivo"
                onChange={handleFile}
              />
              {document !== null ? (
                <span>{document.name}</span>
              ) : (
                <span>Nenhum documento selecionado</span>
              )}
            </div>
            <button type="submit">Salvar</button>
            {id &&
            <button type="button" onClick={()=>togglePostModal(id) } style={{backgroundColor: "#f87b7b", color: "#fff", marginTop: "-10px"}}>Excluir</button>
            }
            </form>
        </div>
      </div>
      {showPostModal && <DeleteModalSop close={togglePostModal} />}
    </div>
  );
}
