import { MdClose, MdDelete } from "react-icons/md";
import "./deleteModalSop.css";
import { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";


import moment from "moment";

export default function DeleteModalSop({ conteudo, close }) {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const { id } = useParams();


  function handleDelete(e){
    e.preventDefault();
    if(id){
      firebase
      .firestore()
      .collection("new-standards")
      .doc(id)
      .delete()
      .then(() => {
        toast.success("Norma excluida com sucesso.")
        history.push("/sop");
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }

  return (
    <div className="deleteSop">
      <div className="container">
        <button className="fecha" onClick={close}>
          <MdClose size={23} color="#fff" />
        </button>

        <div>
       
          <h2>Exclusão de SOP</h2>
        
          <div className="row">
            <span >
              Tem certeza que deseja excluir está SOP?
            </span>
          </div>
          <div className="button">
            <button className="cancel" onClick={close}>
              Cancelar
            </button>
            <button className="delete" onClick={handleDelete}>
              Excluir
            </button>
          </div>
       

        </div>
      </div>
    </div>
  );
}
// “  ”