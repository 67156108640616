import { useState, useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import "./signin.css";
import logo from "../../assets/Logo.png";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";



function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { signIn, loadingAuth } = useContext(AuthContext);

  function handleSubmit(e) {
    e.preventDefault();
    if (email !== "" && password !== "") {
      signIn(email, password);
    }
  }

  function handleChangePassword(e) {
    if(email !== ''){
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.info("Verifique seu e-mail");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Digite um e-mail válido")
      });
    }
  }

  return (
    <div className="container-center">
      <div className="login">
        <div className="login-area">
          <img src={logo} alt="Logotipo da Language Associates" />
        </div>
        <form onSubmit={handleSubmit}>
          <h1>Entrar</h1>
          <input
            type="text"
            placeholder="email@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="********"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">
            {loadingAuth ? "Carregando..." : "Acessar"}
          </button>
        </form>
        <button onClick={handleChangePassword} style={{background: 'none', border: 'none', textDecoration: 'underline', color: '#04f6de', paddingBottom: '10px', marginTop:'-10px'}}>Esqueci minha senha</button>
        {/* <Link to='/register'>Criar uma conta</Link> */}
      </div>
    </div>
  );
}

export default SignIn;
