import React, { useContext, useEffect, useState } from "react";
import {
  MdDashboard,
  MdExitToApp,
  MdLanguage,
  MdPerson,
  MdWeb,
  MdControlPoint,
  MdAdd,
  MdGroupAdd,
  MdFace
} from "react-icons/md";
import { BiKey } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import firebase from "../../services/firebaseConnection";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import Logo from "../../assets/Logo.png";
import { AuthContext } from "../../contexts/auth";

import "./header.css";

export default function Header() {
  const { signOut } = useContext(AuthContext);
  const [toggle, setToggle] = useState(false);
  const [profile, setProfile] = useState({});
  const [changeDate, setChangeDate] = useState(0);
  const [idUser, setIdUser] = useState(false);

  useEffect(() => {
    setProfile(JSON.parse(sessionStorage.getItem("LASOP")));

    return () => {};
  }, []);

  
  const handleClick = () => {
    setToggle(toggle => !toggle );
  };
 

  function order(a, b) {
    return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
  }

  function grupoUsuario() {
    
    return profile.grupos.sort(order).map((valor) => (
      <NavLink
        key={valor.label}
        to={`/standards/${valor.value}`}
        activeClassName="selected"
        style={{color: 'white'}}
      >
        {valor.value}
      </NavLink>
 
    ));
  }


  useEffect(()=>{
  async function loadId(){
    await firebase
    .firestore()
    .collection("new-users")
    .doc(profile.uid)
    .get()
    .then((snapshot) => {
      setChangeDate(snapshot.data().dataAlteracao);
      setIdUser(true);
    })
    .catch((err) => {
      console.log("Erro no ID recebido: ", err);
      setIdUser(false);
    });
  }
  loadId();
  
  });

  return (
    <div className="sidebar">
      <div>
        <img src={Logo} alt="Logotipo da Language Associates" />
      </div>
      {profile.isAdmin ? (
        <>
          <NavLink
            to="#"
            onClick={handleClick}
            className={changeDate === "" ? "hiddenMenuItem" : undefined}
          >
            <MdWeb color="#000" size={24} />
            SOPs
          </NavLink>
          {toggle && grupoUsuario()}
          <NavLink
            to="/records"
            activeClassName="selected"
            className={changeDate === "" ? "hiddenMenuItem" : undefined}
          >
            <MdPerson color="#000" size={24} />
            Cadastros
          </NavLink>
          <NavLink
          to="/code"
          activeClassName="selected"
          className={changeDate === "" ? "hiddenMenuItem" : undefined}
          >
            <MdAdd color="#000" size={24}/>
            Códigos
          </NavLink>
          <NavLink
          to="/group"
          activeClassName="selected"
          className={changeDate === "" ? "hiddenMenuItem" : undefined}
          >
            <MdGroupAdd color="#000" size={24}/>
            Grupos
          </NavLink>
          <NavLink
          to="/typeuser"
          activeClassName="selected"
          className={changeDate === "" ? "hiddenMenuItem" : undefined}
          >
            <MdFace color="#000" size={24}/>
            Tipos de usuário
          </NavLink>
          <NavLink
          to="/new"
          className={changeDate === "" ? "hiddenMenuItem" : undefined}
          >
            <MdControlPoint color="#000" size={24}/>
            Inclusão de SOP
          </NavLink>

          <NavLink
            to="/wordsearch"
            activeClassName="selected"
            className={changeDate === "" ? "hiddenMenuItem" : undefined}
          >
            <MdLanguage color="#000" size={24} />
            Word Search
          </NavLink>

          <NavLink to="/changePassword">
            <BiKey color="#000" size={24} />
            Altere sua senha
          </NavLink>
          <NavLink
            to="/dashboard"
            activeClassName="selected"
            className={changeDate === "" ? "hiddenMenuItem" : undefined}
          >
            <MdDashboard color="#000" size={24} />
            Logs de leitura
          </NavLink>
          <NavLink to="/" onClick={() => signOut()}>
            <MdExitToApp color="#000" size={24} />
            Sair
          </NavLink>
        </>
      ) : (
        // <ProSidebar>
        //   <Menu>
        //     <MenuItem icon={<MdWeb color="#000" size={24} />}>SOPs</MenuItem>
        //     <SubMenu>
        //       <MenuItem>{grupoUsuario()}</MenuItem>
        //     </SubMenu>
        //     <MenuItem icon={<MdExitToApp color="#000" size={24} />}>
        //     Sair
        //     <NavLink to="/" onClick={() => signOut()}/>
        //     </MenuItem>
        //   </Menu>
        // </ProSidebar>
        <>
          <NavLink
            to="#"
            onClick={handleClick}
            className={changeDate === "" ? "hiddenMenuItem" : undefined}
          >
            <MdWeb color="#000" size={24} />
            SOPs
          </NavLink>
          {toggle && grupoUsuario()}
          <NavLink to="/changePassword">
            <BiKey color="#000" size={24} />
            Altere sua senha
          </NavLink>
          <NavLink to="/" onClick={() => signOut()}>
            <MdExitToApp color="#000" size={24} />
            Sair
          </NavLink>
        </>
      )}
    </div>
  );
}
