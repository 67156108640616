import Header from "../../components/Header";
import firebase from "../../services/firebaseConnection";
import React, { useState, useEffect } from "react";
import DeleteModalSop from '../../components/DeleteModalSop';
import "./dashboard.css";
import Select from "react-select";

import Title from "../../components/Title";
import { MdDashboard } from "react-icons/md";
import HeaderTest from "../../components/HeaderTest";

export default function Dashboard() {
  const [changeDate, setChangeDate] = useState(0);
  const [idUser, setIdUser] = useState(false);
  const [profile, setProfile] = useState({});
  const [countUsers, setCountUsers] = useState("");
  const [countSops, setCountSops] = useState("");
  const [countCodes, setCountCodes] = useState("");
  const [getReadSops, setGetReadSops] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState("");
  const [sop, setSop] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [unReadSops, setUnReadSops] = useState([]);

  const listRef = firebase
  .firestore()
  .collection("new-standards")
  .orderBy("titulo");
  

  useEffect(() => {
    setProfile(JSON.parse(sessionStorage.getItem("LASOP")));

    return () => {};
  }, []);


  const loadId = firebase
  .firestore()
  .collection("new-users")
  .doc(profile.uid)
  .get()
  .then((snapshot) => {
    setChangeDate(snapshot.data().dataAlteracao);
  })
  .catch((err) => {
    console.log("Erro no ID recebido: ", err);
  });

  useEffect(() => {
    async function filterUsersName() {
      await firebase
        .firestore()
        .collection("new-users")
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((perfil) => {
            lista.push({
              nome: perfil.data().nome,
            });
          });
          if (lista.length === 0) {
            console.log("Nenhum grupo encontrado");
            return;
          }
          setUsers(lista);
        })
        .catch((error) => {
          console.log("Deu algum erro!");
        });
    }

    filterUsersName();
  }, []);

  useEffect(() => {
    if(userSelected !== ''){
    async function loadReadSopsUsers() {
      await 
      firebase
      .firestore()
      .collection("userReadSop")
      .where('nameUser', '==', userSelected.value)
        .get()
        .then((snapshot) => {
          updateState(snapshot);
        })
        .catch((err) => {
          console.log(err);
        });

      setLoading(false);
    }
    loadReadSopsUsers();
  }

    return () => {};
  }, [userSelected]);
  
  async function updateState(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];
      snapshot.forEach((doc) => {
        lista.push({
          id: doc.idUser,
          nome: doc.data().nameUser,
          titulo: doc.data().titulo,
          versao: doc.data().versao,
        });
      });
      setGetReadSops((getUsers) => [...getUsers, ...lista]);
    } else {
      setIsEmpty(true);
      let lista = []
      setGetReadSops(lista)
    }
  }

  useEffect(() => {
    async function loadStandards() {
      await listRef
        .get()
        .then((snapshot) => {
          getSops(snapshot);
        })
        .catch((err) => {
          console.log(err);
          setLoadingMore(false);
        });

      setLoading(false);
    }

    loadStandards();

    return () => {};
  }, []);

  async function getSops(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;
    
    if (!isCollectionEmpty) {
      let lista = [];
      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          codigo: doc.data().codigo,
          titulo: doc.data().titulo
        });
      });
      setSop(lista);
    } else {
      setIsEmpty(true);
      let lista = []
      setSop(lista)
    }
  }



  useEffect(() => {
  if(userSelected !== ''){
    async function getUnReadSops(){

      setUnReadSops("");
      let listaSop  = [];
      let listaReadSop = [];
      let listaUnReadSop =  [];
      listaSop = sop.map(item => item.titulo)
      listaReadSop = getReadSops.map((item)=>item.titulo)
      listaUnReadSop = listaSop.filter((item) => listaReadSop !== item)

      

      // const ResultArrayObjOne = arrayObjOne.filter(({ userId: userId }) => !arrayObjTwo.some(({ empId: empId }) => empId === userId));

    // console.log("SOP" + sop.map(item => item.titulo));  
    // console.log("GETREADSOPS" + getReadSops.map((item)=>item.titulo)) 
    // console.log("UNREADSOPS" + listaUnReadSop) 
    // console.log("TYPEOF LISTA" + typeof(listaSop))
    // console.log("TYPEOF UNREADSOPS" + typeof(unReadSops))
    

    // for(let i=0; i<sop.length; i++){
      
    //   let sopTitulo = sop[i].titulo;
    //   let controle = false;
    //   for(let j=0; j<getReadSops.length; j++){
    //     console.log("FOR" + getReadSops)
    //     if(getReadSops[j].titulo == sopTitulo){
    //       controle = true;
    //     }
    //   }
    //   if(controle == false){
    //     lista.push(sop[i]);
    //   }

    // }

    // lista = sop.filter(({value: titulo1}) => !getReadSops.some(({value: titulo2}) => titulo2 == titulo1))
    

    console.log("LISTA" + listaSop);
    console.log("LIDOS" + listaReadSop);
    setUnReadSops(listaUnReadSop)
    console.log("NÃO LIDOS" + unReadSops);
    }
    getUnReadSops();
  }

  }, [userSelected])

  function order(a, b) {
    return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
  }

  const usersOptions = users.map((item) => {
    return{
      label: item.nome,
      value: item.nome
    }
  }).sort(order);

  return (
    <div>
    <Header />
    <div className="content">
      <Title name="Logs de leitura">
      <MdDashboard size={25} />
      </Title>
      <div className="containerDashboard">
        {changeDate === "" && 
          <div className="primeiroAcesso">
            <div className="titulo">
            <h2>Olá {profile.nome}!</h2>
            </div>
            <h3>
              Antes de prosseguir,
              altere sua senha clicando no botão ao
              lado. Sua senha deve conter no mínimo 6
              caracteres, sendo pelo menos 1 letra
              maiúscula, 1 letra minúscula, 1 número e
              1 caractere especial.
            </h3>
            <br/>
            <div className="titulo">
            <h2>Hello {profile.nome}!</h2>
            </div>
            <h3>
            To proceed, change your password by clicking the button to the left. 
            Password must have at least 6 characters in length, and contain at least
            1 uppercase, 1 lowercase, 1 number and 1 special character.
            </h3>
          </div>
          
        }
      {profile.isAdmin & changeDate!== "" &&
        <>
        <div className="select">
        <Select
        closeMenuOnSelect={true}
        value={userSelected}
        onChange={setUserSelected}
        options={usersOptions}
        />
        <div className="readSop">
          <div className="titleLidos">
          <h2>SOPs lidos</h2>
          </div>
          <div className="boxLidos">
              <table>
              <thead>
                <tr>
                  <th scope="col">Título</th>
                </tr>
              </thead>
              <tbody>
                {getReadSops.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="E-Mail">{item.titulo}</td>
              
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </div>
            </div>
            <div className="unReadSop">
          <div className="titleLidos">
          <h2>SOPs não lidos</h2>
          </div>
          <div className="boxNaoLidos">
              <table>
              <thead>
                <tr>
                  <th scope="col">Título</th>
                </tr>
              </thead>
              <tbody>
                {unReadSops.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="E-Mail">{item}</td>
              
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </div>
            </div>
            <div className="buttons">
            <button type="button" className="sendEmail">Email não lidos</button>
            <button type="button" className="geraRelatorio">Gerar relatório</button>
            </div>
            </div>
        </>
      }
      </div>
      </div>
      </div>
  );
}
