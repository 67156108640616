import { MdClose } from "react-icons/md";
import "./deleteModalCode.css";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";

export default function DeleteModalCode({ conteudo, close }) {
  const history = useHistory();
  const { id } = useParams();


  function handleDelete(e){
    e.preventDefault();
    if(id){
      firebase
      .firestore()
      .collection("codes")
      .doc(id)
      .delete()
      .then(() => {
        toast.success("Código excluido com sucesso.")
        history.push("/code");
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }

  return (
    <div className="deleteCode">
      <div className="container">
        <button className="fechaCode" onClick={close}>
          <MdClose size={23} color="#fff" />
        </button>

        <div>
       
          <h2>Exclusão de Código</h2>
        
          <div className="row">
            <span >
              Tem certeza que deseja excluir este código?
            </span>
          </div>
          <div className="button">
            <button className="cancel" onClick={close}>
              Cancelar
            </button>
            <button className="delete" onClick={handleDelete}>
              Excluir
            </button>
          </div>
       

        </div>
      </div>
    </div>
  );
}
// “  ”