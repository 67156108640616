import { useState, useEffect } from "react";
import "./newGroup.css";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { MdPersonAdd } from "react-icons/md";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import DeleteModalGroup from "../../components/DeleteModalGroup";

export default function NewGroup() {
  const { id } = useParams();
  const history = useHistory();

  const [loadGroups, setLoadGroups] = useState(true);
  const [groups, setGroups] = useState([]);
  const [idUser, setIdUser] = useState(false);
  const [uSelected, setUSelected] = useState([]);
  const [grupo, setGrupo] = useState("");
  const [showPostModal, setShowPostModal] = useState(false);



  function togglePostModal(id) {
    setShowPostModal(!showPostModal);
  }

  useEffect(() => {
    async function loadGroups() {
      await firebase
        .firestore()
        .collection("groups")
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              grupo: doc.data().grupo,
            });
          });
          if (lista.length === 0) {
            console.log("Nenhum grupo encontrado");
            setGroups([{ id: "1", codigo: "TESTE" }]);
            setLoadGroups(false);
            return;
          }

          setGroups(lista);
          setLoadGroups(false);

          if (id) {
            loadId(lista);
          }
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadGroups(false);
          setGroups([{ id: "1", grupo: "" }]);
        });
    }

    loadGroups();
  }, []);


  async function loadId(lista) {
    await firebase
      .firestore()
      .collection("groups")
      .doc(id)
      .get()
      .then((snapshot) => {
        setGrupo(snapshot.data().grupo);

        let index = lista.findIndex((item) => item.id === snapshot.data().id);

        setUSelected(index);
        setIdUser(true);
      })
      .catch((err) => {
        console.log("Erro no ID recebido: ", err);
        setIdUser(false);
      });
         
  }

  //Cadastra GRUPOS no banco
  async function handleRegisterGroup(e) {
    e.preventDefault();
    if (id) {
      await firebase
        .firestore()
        .collection("groups")
        .doc(id)
        .update({
          grupo: grupo,
        })
        .then(() => {
          toast.success("Grupo editado com sucesso");
          history.push("/group");
        })
        .catch((err) => {
          console.log(err);
        });
    }else{
    if (grupo !== "") {
      await firebase
        .firestore()
        .collection("groups")
        .add({
            grupo: grupo,
        })
        .then(() => {
            toast.info("Grupo cadastrado com sucesso");
            history.push("/group");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Erro ao cadastrar código");
        });
    } else {
      toast.warning("Preencha todos os campos!");
    }
    }
    // loadGroups();
  }


  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Grupos">
          <MdPersonAdd size={25} />
        </Title>

        <div className="container-center">
          <div className="container-groups">
            <form className="form-codes" onSubmit={handleRegisterGroup}>
            <input
                type="text"
                placeholder="Cadastro de grupos"
                value={grupo}
                onChange={(e) => setGrupo(e.target.value)}
              />
              <button type="submit">Salvar</button>
              {id &&
              <button type="button" onClick={()=>togglePostModal(id) } style={{backgroundColor: "#f87b7b", color: "#fff", marginTop: "-10px"}}>Excluir</button>
              }
            </form>
          </div>
      </div>
      {showPostModal && <DeleteModalGroup close={togglePostModal} />}
    </div>
    </div>
  );
}
