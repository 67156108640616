import {  useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import "./modal.css";
import { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import moment from "moment";
import firebase from "../../services/firebaseConnection";


export default function Modal({ conteudo, close }) {
  const [checked, setChecked] = useState(false);
  const [profile, setProfile] = useState({});

  const { user } = useContext(AuthContext);
  let email = `mailto:lasop@associates.com.br?subject=SOP ${conteudo.titulo}&body=O usuário ${user.nome}, e-mail ${user.email}, solicitou a tradução deste SOP para o inglês.`;

  useEffect(() => {
    setProfile(JSON.parse(sessionStorage.getItem("LASOP")));

    return () => {};
  }, []);

  useEffect(() => {
  let lista = [];
  var loadUserReadSop = firebase.firestore().collection("userReadSop");
  loadUserReadSop = loadUserReadSop.where("idUser", "==", user.uid);
  loadUserReadSop = loadUserReadSop.where("titulo", "==", conteudo.titulo);
  loadUserReadSop.get()
  .then((snapshot) => {
   snapshot.forEach((user)=> {
     lista.push({
        id: user.data().idUser,
        titulo: user.data().titulo
     })
    })
  })
  .catch((err) => {
    console.log("Erro no ID recebido: ", err);
  });
  
  const timer = setTimeout(() => {
    lista.length !== 0 && setChecked(true)
  }, 300);

  return () => clearTimeout(timer);

}, [conteudo.titulo])

  const handleChange = () => {
    setChecked(!checked)
    registerReadSop();
  }

 async function registerReadSop(){
    await firebase
    .firestore()
    .collection("userReadSop")
    .add({
      idUser: profile.uid,
      nameUser: profile.nome,
      profileUser: profile.perfilUsuario,
      titulo: conteudo.titulo,
      versao: conteudo.versao,
    })
    .then(() => {
      console.log("LIDA");
    })
    .catch((error) => {
      console.log(error);
    });
  
  }

  return (
    <div className="modal">
      <div className="container">
        <button className="close" onClick={close}>
          <MdClose size={23} color="#fff" />
        </button>

        <div>
          <h2>Detalhes do SOP</h2>

          <div className="row">
            <span >
              Título: <a style={{fontWeight: "100"}}>{conteudo.titulo}</a>
            </span>
          </div>
          <div className="row">
            <p>
              Descrição: <a style={{fontWeight: "100"}}>{conteudo.descricao}</a>
            </p>
          </div>
          <div className="row">
            <span>Grupos: <a style={{fontWeight: "100"}}>{conteudo.arrayGrupos.join(", ")}</a></span>
          </div>
          <div className="row">
            <span>
              Elaborado por: <a style={{fontWeight: "100"}}>{conteudo.elaboradoPor.value}</a>
            </span>
            <span>
              Data elaboração: <a style={{fontWeight: "100"}}>{conteudo.dataElaboracao !== "" ? moment(conteudo.dataElaboracao).format("DD/MM/YYYY") : "N/A"}</a>
            </span>
          </div>
          <div className="row">
            <span>
              Revisado por: <a style={{fontWeight: "100"}}>{conteudo.revisadoPor.value}</a>
            </span>
            <span>
              Data revisão: <a style={{fontWeight: "100"}}>{conteudo.dataRevisao !== "" ?  moment(conteudo.dataRevisao).format("DD/MM/YYYY") : "N/A"}</a>
            </span>
          </div>
          <div className="row">
            <span>
              Aprovado por: <a style={{fontWeight: "100"}}>{conteudo.aprovadoPor.value}</a>
            </span>
            <span>
              Data aprovação: <a style={{fontWeight: "100"}}>{conteudo.dataAprovacao !== "" ? moment(conteudo.dataAprovacao).format("DD/MM/YYYY") : "N/A"}</a>
            </span>
          </div>
          <div className="row">
            <span>
              Vigência: <a style={{fontWeight: "100"}}>
                         {conteudo.vigenciaInicial !== "" ? moment(conteudo.vigenciaInicial).format("DD/MM/YYYY ") : "PND "}
                        </a>
                        a{" "}
                        <a style={{fontWeight: "100"}}>
                        {conteudo.vigenciaFinal !== "" ? moment(conteudo.vigenciaFinal).format("DD/MM/YYYY") : "PND"}
                        </a>
            </span>
            <span>
              Código e versão: 
              <a style={{fontWeight: "100"}}>
                {` ${conteudo.codigo.value !== null ? conteudo.codigo.value : "0"}_${conteudo.versao !== null ? conteudo.versao : "V0"}`}
              </a>
            </span>
          </div>
      
          <div className='isAccept'>
            <input 
            type="checkbox"
            className={checked ? 'hiddenMenuItem' : undefined}
            checked={checked}
            onChange={handleChange}
            />
            <a>Assinale esta opção para indicar que você leu, entendeu e seguirá as instruções fornecidas neste procedimento</a> 
          </div>
  
          <div className="openFile" style={conteudo.documento === null ? {pointerEvents: "none", opacity: ".5"} : null}>
            <a href={conteudo.documento} target="_blank" onClick={close} >
              Abrir arquivo
            </a>
          </div>
          <div className="info">
            <h5>This procedure is available in Portuguese only.</h5>
          </div>
          <div className="info">
            <h5>
            If you need an English version,{" "}
              <a href={email} target="_blank">
              click here.
              </a>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
