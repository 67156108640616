import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

let firebaseConfig = {
  apiKey: "AIzaSyBbNZxjbM8REjnYh58Ef4kj3_32cOC7uc0",
  authDomain: "lasop-c0441.firebaseapp.com",
  projectId: "lasop-c0441",
  storageBucket: "lasop-c0441.appspot.com",
  messagingSenderId: "692598651251",
  appId: "1:692598651251:web:43e606e5e4f9385a8ced06",
  measurementId: "G-HR9TBVGKHT",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
