import { useState, useContext, useEffect } from "react";
import "./newRecords.css";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { MdPersonAdd } from "react-icons/md";
import { AuthContext } from "../../contexts/auth";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import DeleteModalUser from "../../components/DeleteModalUser";


export default function NewRecords() {
  const { id } = useParams();
  const history = useHistory();

  const [loadCodes, setLoadCodes] = useState(true);
  const [codes, setCodes] = useState([]);
  const [loadGroups, setLoadGroups] = useState(true);
  const [groups, setGroups] = useState([]);
  const [groupSelected, setGroupSelected] = useState(0);
  const [loadTypeUsers, setLoadTypeUsers] = useState(true);
  const [typeUsers, setTypeUsers] = useState([]);
  const [typeUserSelected, setTypeUserSelected] = useState(0);
  const [idUser, setIdUser] = useState(false);
  const [uSelected, setUSelected] = useState([]);
  const [perfilUsuario, setPerfilUsuario] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState('');
  const [showPostModal, setShowPostModal] = useState(false);


  const { signUp } = useContext(AuthContext);

  const changeDate = "";

  function togglePostModal(id) {
    setShowPostModal(!showPostModal);
  }

  useEffect(() => {
    async function loadCodes() {
      await firebase
        .firestore()
        .collection("codes")
        .orderBy("codigo", "asc")
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              codigo: doc.data().codigo,
            });
          });
          if (lista.length === 0) {
            console.log("Nenhum código encontrado");
            setCodes([{ id: "1", codigo: "" }]);
            setLoadCodes(false);
            return;
          }

          setCodes(lista);
          setLoadCodes(false);
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadCodes(false);
          setCodes([{ id: "1", codigo: "" }]);
        });
    }

    loadCodes();
  }, []);

  useEffect(() => {
    async function loadGroups() {
      await firebase
        .firestore()
        .collection("groups")
        .orderBy("grupo", "asc")
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              grupo: doc.data().grupo,
            });
          });
          if (lista.length === 0) {
            console.log("Nenhum grupo encontrado");
            setGroups([{ id: "1", codigo: "TESTE" }]);
            setLoadGroups(false);
            return;
          }

          setGroups(lista);
          setLoadGroups(false);

          if (id) {
            loadId(lista);
          }
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadGroups(false);
          setGroups([{ id: "1", grupo: "" }]);
        });
    }

    loadGroups();
  }, []);

  async function loadId(lista) {
    await firebase
      .firestore()
      .collection("new-users")
      .doc(id)
      .get()
      .then((snapshot) => {
        setNome(snapshot.data().nome);
        setEmail(snapshot.data().email);
        setTypeUserSelected(snapshot.data().tipoUsuario);
        setGroupSelected(snapshot.data().grupo);
        setPassword(snapshot.data().password);
        setPerfilUsuario(snapshot.data().perfilUsuario);

        let index = lista.findIndex((item) => item.id === snapshot.data().id);

        setUSelected(index);
        setIdUser(true);
        console.log(`Grupos: ${groupSelected}`);
      })
      .catch((err) => {
        console.log("Erro no ID recebido: ", err);
        setIdUser(false);
      });
  }

  useEffect(() => {
    async function loadTypeUsers() {
      await firebase
        .firestore()
        .collection("type-users")
        .orderBy("tipoUsuario", "asc")
        .get()
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              tipoUsuario: doc.data().tipoUsuario,
            });
          });
          if (lista.length === 0) {
            console.log("Nenhum grupo encontrado");
            setTypeUsers([{ id: "1", codigo: "TESTE" }]);
            setLoadTypeUsers(false);
            return;
          }

          setTypeUsers(lista);
          setLoadTypeUsers(false);
        })
        .catch((error) => {
          console.log("Deu algum erro!");
          setLoadTypeUsers(false);
          setTypeUsers([{ id: "1", tipoUsuario: "" }]);
        });
    }

    loadTypeUsers();
  }, []);


  //Cadastra novo usuário no banco
  async function handleRegisterUser(e) {
    e.preventDefault();
    // const admin = "Administrador";
    const arrayPerfilUsuario = perfilUsuario.map((item) => {
      return item.value;
    });
    const isAdmin = arrayPerfilUsuario.some(admin => admin === "Administrador");
    if (idUser) {
      await firebase
        .firestore()
        .collection("new-users")
        .doc(id)
        .update({
          nome: nome,
          email: email,
          password: password,
          perfilUsuario: perfilUsuario,
          arrayPerfilUsuario: arrayPerfilUsuario,
          tipoUsuario: typeUserSelected,
          grupo: groupSelected,
          isAdmin: isAdmin
        })
        .then(() => {
          toast.success("Usuário editado com sucesso");
          history.push("/records");
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    } else {
      await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (value) => {
        var uid = value.user.uid;
        await firebase
          .firestore()
          .collection("new-users")
          .doc(uid)
          .set({
            nome: nome,
            email: email,
            password: password,
            perfilUsuario: perfilUsuario,
            tipoUsuario: typeUserSelected,
            grupo: groupSelected,
            dataAlteracao: changeDate,
            arrayPerfilUsuario: arrayPerfilUsuario, 
            isAdmin: isAdmin
          })
          .then(() => {
            toast.success("Usuário cadastrado com sucesso!");
            history.push("/records");
          });
        })
    }
  }


  function handleChangePassword(e) {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.info("E-mail enviado com sucesso");
        history.push("/records");
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function order(a, b) {
    return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
  }



  const optionsTypeUser = typeUsers.sort(order).map((item, index) => {
    return {
      label: item.tipoUsuario,
      value: item.tipoUsuario,
    };
  }).sort(order);

  const options = groups.map((item) => {
    return {
      label: item.grupo,
      value: item.grupo,
    };
  }).sort(order);

  const optionsUserProfile = [
    {value: "Administrador", label:"Administrador"},
    {value:"Aprovador", label:"Aprovador"},
    {value:"Redator", label:"Redator"},
    {value:"Revisor", label:"Revisor"},
    {value:"Usuário", label:"Usuário"}
  ] 

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Cadastros">
          <MdPersonAdd size={25} />
        </Title>
        <div className="container-center">
          <div className="container-new-user">
            <form className="form-new-user" onSubmit={handleRegisterUser}>
              <label>Nome</label>
              <input
                type="text"
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
              <label>E-Mail</label>
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Tipos de usuário</label>
              {loadTypeUsers ? (
                <input type="text" disabled={true} value="Carregando..." />
              ) : (
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  value={typeUserSelected}
                  onChange={setTypeUserSelected}
                  options={optionsTypeUser}
                />
              )}
              <label>Grupos</label>
              {loadGroups ? (
                <input type="text" disabled={true} value="Carregando..." />
              ) : (
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  value={groupSelected}
                  onChange={setGroupSelected}
                  options={options}
                />
              )}
              <label>Senha</label>
              {!id ? (
                <input
                  type="password"
                  placeholder="Senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              ) : (
                <button
                  type="reset"
                  onClick={handleChangePassword}
                  className="resetSenha"
                >
                  Resetar senha
                </button>
              )}
              <label style={{marginTop: "-10px"}}>Perfil de usuário</label>
              <Select
                  closeMenuOnSelect={false}
                  isMulti
                  value={perfilUsuario}
                  onChange={setPerfilUsuario}
                  options={optionsUserProfile}
                />
              <button type="submit" style={{marginTop: "15px"}}>Salvar</button>
              {id &&
              <button type="button" onClick={()=>togglePostModal(id) } style={{backgroundColor: "#f87b7b", color: "#fff", marginTop: "-10px"}}>Excluir</button>
              }
              </form>
          </div>
        </div>
      </div>
      {showPostModal && <DeleteModalUser close={togglePostModal} />}
    </div>
  );
}
