import "./standards.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import firebase from "../../services/firebaseConnection";
import Header from "../../components/Header";
import Title from "../../components/Title";
import {
  MdWeb,
  MdAddCircleOutline,
  MdVisibility,
  MdEdit,
} from "react-icons/md";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";

export default function Standards() {
  const { dep } = useParams();

  const listRef = firebase
    .firestore()
    .collection("new-standards")
    .where("arrayGrupo", "array-contains", dep)
    .orderBy("titulo");

  const [sop, setSop] = useState([]);
  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    setProfile(JSON.parse(sessionStorage.getItem("LASOP")));

    return () => {};
  }, []);

  useEffect(() => {
    async function loadStandards() {
      await listRef
        .get()
        .then((snapshot) => {
          updateState(snapshot);
        })
        .catch((err) => {
          console.log(err);
          setLoadingMore(false);
        });

      setLoading(false);
    }

    loadStandards();

    return () => {};
  }, [dep]);


  async function updateState(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;
    
    if (!isCollectionEmpty) {
      let lista = [];
      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          codigo: doc.data().codigo,
          titulo: doc.data().titulo,
          descricao: doc.data().descricao,
          arrayGrupos: doc.data().arrayGrupo,
          grupos: doc.data().grupo,
          elaboradoPor: doc.data().elaboracao,
          dataElaboracao: doc.data().dataElaboracao,
          revisadoPor: doc.data().revisao,
          dataRevisao: doc.data().dataRevisao,
          aprovadoPor: doc.data().aprovacao,
          dataAprovacao: doc.data().dataAprovacao,
          vigenciaInicial: doc.data().vigenciaInicial,
          vigenciaFinal: doc.data().vigenciaFinal,
          versao: doc.data().versao,
          documento: doc.data().documento,
        });
      });
      // const lastDoc = snapshot.docs[snapshot.docs.length -1];
      setSop(lista);
      // setSop(sop => [...sop,...lista]);
      // setLastDocs(lastDoc);
    } else {
      setIsEmpty(true);
      let lista = []
      setSop(lista)
    }
  }

  function togglePostModal(item) {
    setShowPostModal(!showPostModal);
    setDetail(item);
  }

  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="SOPs">
            <MdWeb size={25} />
          </Title>

          <div className="container standards">
            <span>Buscando SOP's...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />

      <div className="content">
        <Title name="SOPs">
          <MdWeb size={25} />
        </Title>

        {profile.isAdmin ? (
          <>
            {/* <Link to="/new" className="new">
              <MdAddCircleOutline size={25} color="#FFF" />
              Novo SOP
            </Link> */}

            <table>
              <thead>
                <tr>
                  <th scope="col">Título</th>
                  <th scope="col">Descrição</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {sop.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Título">{item.titulo}</td>
                      <td data-label="Descrição">{item.descricao}</td>
                      <td data-label="#">
                        <button
                          className="action"
                          style={{ backgroundColor: "#3583f6" }}
                          onClick={() => togglePostModal(item)}
                        >
                          <MdVisibility color="#fff" size={17} />
                        </button>
                        <Link
                          className="action"
                          style={{ backgroundColor: "#f6a935" }}
                          to={`/new/${item.id}`}
                        >
                          <MdEdit color="#fff" size={17} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* {loadingMore && <h3 style={{textAlign: 'center', marginTop: 15}}>Buscando dados...</h3>}        */}
            {/* {!loadingMore && !isEmpty && <button className="btn-more" onClick={handleMore}>Buscar mais</button>} */}
          </>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th scope="col">Título</th>
                  <th scope="col">Descrição</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {sop.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Título">{item.titulo}</td>
                      <td data-label="Descrição">{item.descricao}</td>
                      <td data-label="#">
                        <button
                          className="action"
                          style={{ backgroundColor: "#3583f6" }}
                          onClick={() => togglePostModal(item)}
                        >
                          <MdVisibility color="#fff" size={17} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* {loadingMore && <h3 style={{textAlign: 'center', marginTop: 15}}>Buscando dados...</h3>}        */}
            {/* {!loadingMore && !isEmpty && <button className="btn-more" onClick={handleMore}>Buscar mais</button>} */}
          </>
        )}
      </div>
      {showPostModal && <Modal conteudo={detail} close={togglePostModal} />}
    </div>
  );
}
