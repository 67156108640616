import "./codes.css";
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { MdAddCircleOutline, MdEdit, MdPersonOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import firebase from "../../services/firebaseConnection";

const listRefCode = firebase
  .firestore()
  .collection("codes")
  .orderBy("codigo", "asc")

export default function Codes() {
  const [getCodes, setGetCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    async function loadCodes() {
      await listRefCode
        .get()
        .then((snapshot) => {
          updateState(snapshot);
        })
        .catch((err) => {
          console.log(err);
        });

      setLoading(false);
    }
    loadCodes();

    return () => {};
  }, []);

  async function updateState(snapshot) {
    const isCollectionEmpty = snapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];
      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          nome: doc.data().codigo,
        });
      });
      setGetCodes((getCodes) => [...getCodes, ...lista]);
    } else {
      setIsEmpty(true);
    }
  }

  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Códigos">
            <MdAddCircleOutline size={25} />
          </Title>

          <div className="container records">
            <span>Buscando usuários...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />

      <div className="content">
      <Title name="Códigos">
            <MdAddCircleOutline size={25} />
          </Title>

        {getCodes.length === 0 ? (
          <div className="container records">
            <span>Nenhum usuário cadastrado...</span>
            <Link to="/newcode" className="new">
              <MdAddCircleOutline size={25} color="#FFF" />
              Novo código
            </Link>
          </div>
        ) : (
          <>
            <Link to="/newcode" className="new">
              <MdAddCircleOutline size={25} color="#FFF" />
              Novo código
            </Link>

            <table>
              <thead>
                <tr>
                  <th scope="col">Código</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {getCodes.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Codigo">{item.nome}</td>
                      <td data-label="#">
                        {/* <button className='action' style={{backgroundColor:"#3583f6"}} onClick={()=>togglePostModal() }>
                                <MdVisibility color="#fff" size={17}/>
                            </button> */}
                        <Link
                          className="action"
                          style={{ backgroundColor: "#f6a935" }}
                          to={`/newcode/${item.id}`}
                        >
                          <MdEdit color="#fff" size={17} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}
